import * as React from "react";

const IndexPage = () => {
  return (
    <main className="container px-6 mx-auto">
      <title>Home Page</title>
      <div className="flex flex-col justify-center min-h-screen px-6 md:px-12 lg:px-24">
        <div className="w-full md:w-4/5 lg:w-4/5 xl:w-3/4 2xl:w-2/4">
          <h1>
            Ricardo Saiz
            <span>Designer and Front End developer</span>
          </h1>
          <p>
            Los últimos 10 años los he dedicado a desarrollar productos
            digitales. En 2019 viajé a Madrid todos los viernes para cursar el
            Programa de Diseño de Interacción en Instituto Tramontana. He
            encontrado mi equilibrio en la combinación de ambas disciplinas
          </p>
          <p>
            He trabajado en empresas como DoubleYou, B-Reel, DigitalYou y ADman
            Media, entre otras. Actualmente trabajo para elrow, como
            desarrollador front-end y diseñador UI/UX.
          </p>
          <p>Con tendencia minimalista, y amante convencido de less-is-more.</p>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ricardo-saiz-segovia-53144329/"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:ricardo.saiz[at]gmail.com"
              >
                E-mail
              </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
